import styled from "styled-components";

import { CountryLink } from "containers/CountryMapper/style";

export const InfoSection = styled.section`
    margin-left: auto;
    margin-right: auto;
    max-width: ${({ theme }) => theme.customTheme.pxToRem(1200)};
    background: ${({ theme }) => theme.customTheme.palette.white.standard};
}
`;

export const BaseContainer = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: ${({ theme }) => theme.customTheme.pxToRem(16)};
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(16)};
`;

export const CountrySection = styled.ul`
  height: auto;
  flex-wrap: wrap;
  display: flex;
  list-style: none;
  margin: 0 0 8px 0;
  padding: 0;
  & ${CountryLink}:nth-child(3n+3) {
    margin-right: 0%;
  }
`;

export const TextInfoContainer = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.customTheme.pxToRem(769)};
  width: 100%;
`;

export const DetailText = styled.h3`
  ${({ theme }) => theme.customTheme.typography.h3},
`;

export const DetailTitle = styled.h2`
  ${({ theme }) => theme.customTheme.typography.h2},
`;

export const DetailParagraph = styled.p`
  ${({ theme }) => theme.customTheme.typography.p},
`;
