import { FunctionComponent } from "react";
import { useTheme } from "styled-components";

const Header: FunctionComponent = () => {
  const theme = useTheme();

  return <theme.customTheme.components.Footer />;
};

export default Header;
