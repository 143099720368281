import { FunctionComponent, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useCookies } from "react-cookie";

import { useDispatch, useSelector } from "react-redux";

import {
  BaseContainer,
  CountrySection,
  DetailParagraph,
  DetailText,
  DetailTitle,
  InfoSection,
  TextInfoContainer,
} from "./style";

import HomePageBanner from "containers/HomePageBanner";
import CountryMapper from "containers/CountryMapper";
import { useRefreshUser } from "utils/UseRefreshUser";
import { userState } from "state/user-slice";
import { setLoader } from "state/config-slice";
import { AppDispatch } from "state/reducers-combiner";
import { useSourceParams } from "utils/useSourceParams";

const LandingPage: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [GetUserInfoFromCookies] = useRefreshUser();
  const [cookies] = useCookies();
  const userInfo = useSelector(userState);
  const [searchParams] = useSearchParams();

  useSourceParams(searchParams);

  useEffect(() => {
    if (location.pathname.length > 1) {
      GetUserInfoFromCookies();
    } else if (cookies["opco"]) {
      window.location.href = `${window.location.origin}/${cookies["opco"]}`;
    } else {
      dispatch(setLoader({ showLoader: false, message: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const countrys = [
    {
      route: "ro",
      name: "Romania",
      src: "static-ppe2/images/countrys/romania.png",
    },
    {
      route: "es",
      name: "Spain",
      src: "static-ppe2/images/countrys/spain.png",
    },
  ];

  return (
    <>
      {!userInfo.loggedIn && (
        <>
          <HomePageBanner
            currentPageText={"Welcome to Vodafone"}
            background="static-ppe2/images/landingpage-background.jpeg"
            showSubtitle={false}
          />
          <InfoSection>
            <BaseContainer>
              <TextInfoContainer>
                <DetailTitle>Your entertainment</DetailTitle>
                <DetailText>
                  Welcome to Vodafone Entertainment, where you can choose one of the top streaming subscription services
                  as part of your smartphone contract. Examples of these include Spotify Premium, Netflix, Now TV
                  Entertainment Pack and Sky Sports.
                </DetailText>
                <DetailParagraph>Select your country and see the special offers you can get:</DetailParagraph>
              </TextInfoContainer>
            </BaseContainer>
            <CountrySection>
              <CountryMapper countrys={countrys}></CountryMapper>
            </CountrySection>
          </InfoSection>
        </>
      )}
    </>
  );
};

export default LandingPage;
