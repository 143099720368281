import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { IconZiggo } from "@vfuk/brand-hifi-icons";
import { IconMobile } from "@vfuk/source-system-icons";

import {
  StyledHeader,
  HeaderContainer,
  IconAnchor,
  AccountContainer,
  StyledAccountNumber,
  LogoutButton,
} from "containers/Header/styles";

import { userState } from "state/user-slice";

const Header: FunctionComponent<{ openLogoutModal: () => void }> = ({ openLogoutModal }) => {
  const userInfo = useSelector(userState);

  return (
    <StyledHeader>
      <HeaderContainer>
        <IconAnchor to="/" aria-label="Go to homepage">
          <IconZiggo />
        </IconAnchor>
        {userInfo.loggedIn && (
          <AccountContainer>
            <StyledAccountNumber>
              {userInfo.choosenChargingId?.chargingId ? `+${userInfo.choosenChargingId?.chargingId}` : ""}
            </StyledAccountNumber>
            <LogoutButton onClick={openLogoutModal} aria-label="Logout">
              <IconMobile />
            </LogoutButton>
          </AccountContainer>
        )}
      </HeaderContainer>
    </StyledHeader>
  );
};

export default Header;
