import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import offersReducer from "./offers-slice";
import configReducer from "./config-slice";
import userReducer from "./user-slice";

export const rootReducer = combineReducers({
  offers: offersReducer,
  config: configReducer,
  user: userReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunkMiddleware],
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;

export type AppStore = ReturnType<typeof setupStore>;

export type AppDispatch = AppStore["dispatch"];
