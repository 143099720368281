import styled from "styled-components";

export const StyledContainer = styled.section`
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-in-out 0.5s;
  width: 100%;
  z-index: 200000000;
  background-color: white;
  display: flex;
  justify-content: center;

  & h2 {
    font-family: ThemeFontRegular;
  }
`;
