import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { I18nextProvider, initReactI18next } from "react-i18next";
import themeWS10 from "@vfuk/core-theme-ws10";
import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

import translationRO from "i18n/ro/resource.json";

import Footer from "containers/Footer";

import translationEN from "i18n/en/resource.json";
import GlobalStyle from "themes/globalStyle";

import { themeCollection } from "themes";
import AppRoutes from "routes/routes-mapping";
import Header from "containers/Header";
import { setupStore } from "state/reducers-combiner";
import { ASSETS_URL } from "config";
import Modal from "containers/Modal";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import getTheme from "utils/getTheme";
import getOpco from "utils/getOpco";
import Loader from "components/Loader";
import LandingPage from "containers/LandingPage";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: translationEN,
      },
      ro: {
        translation: translationRO,
      },
    },
    lng: getOpco(),
    fallbackLng: "en",
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    supportedLngs: ["en", "ro"],
  });

const store = setupStore();
themeWS10.setBaseAssetLocation(`${ASSETS_URL}/static-ppe2/`);

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={{ ...themeWS10, ...themeCollection[getTheme()] }}>
        <themeWS10.globalStyles />
        <GlobalStyle />
        <CookiesProvider>
          <Provider store={store}>
            <BrowserRouter basename={`/${getOpco()}`}>
              <ScrollToTop />
              <Modal />
              <Header />
              {getOpco().length > 0 ? <AppRoutes /> : <LandingPage />}
              <Loader />
              <Footer />
            </BrowserRouter>
          </Provider>
        </CookiesProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
