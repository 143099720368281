import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useTheme } from "styled-components";

import { BreadcrumbsLink, BreadcrumbsWrapper, BreadcrumbsText } from "components/Breadcrumbs/styles";

import Arrow from "components/Arrow";

interface IProps {
  text: string;
}

const Breadcrumbs: FunctionComponent<IProps> = ({ text }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <BreadcrumbsWrapper>
      <BreadcrumbsLink to="/">
        <BreadcrumbsText datat-testid="back-home-btn">{t("Home")}</BreadcrumbsText>
      </BreadcrumbsLink>
      <Arrow color={theme.customTheme.palette.grey.dark} size={15} direction="right" />
      <BreadcrumbsText data-testid="offer-detail-btn">{text}</BreadcrumbsText>
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumbs;
