import styled from "styled-components";

import { PrimaryButton } from "components/Button";

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 88px;
`;

export const StyledPageTitle = styled.h1`
  font-family: ThemeFontRegular;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  text-align: center;
  opacity: 1;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
`;

export const StyledDescription = styled.h2`
  font-family: ThemeFontRegular;
  color: #333333;
  text-align: center;
  opacity: 1;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 22px;
`;

export const StyledBoxArea = styled.section`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ebebeb;
  padding: 12px;

  &:only-child,
  &:last-child {
    border: none;
  }
`;

export const BackToHomepageButton = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  height: ${({ theme }) => theme.customTheme.pxToRem(40)};
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    margin: auto;
    width: fit-content;
  }
`;

export const IconStyled = styled.i`
  display: flex;
`;
