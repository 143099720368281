import { FunctionComponent } from "react";

import { useTheme } from "styled-components";
import { useDispatch } from "react-redux";

import LogoutModal from "containers/LogoutModal";

import { AppDispatch } from "state/reducers-combiner";
import { setModal } from "state/config-slice";

const Header: FunctionComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();

  const openLogoutModal = () => {
    dispatch(
      setModal({ showModal: true, cross: false, type: "logout", children: <LogoutModal />, customButtons: <></> }),
    );
  };

  return <theme.customTheme.components.Header openLogoutModal={openLogoutModal} />;
};

export default Header;
