import { Link } from "react-router-dom";
import styled from "styled-components";

import { StyledRoundedImage } from "components/RoundedImage/styles";

export const CountryLink = styled(Link)`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 3.3em;
  padding: 0.8em;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
  width: 33%;
  margin-right: 0.5%;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 100%;
    margin-right: 0px;
  }
`;

export const CountryRoundedImage = styled(StyledRoundedImage)`
  border: 2px solid #ffffff;
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.15);
`;

export const CountryLabel = styled.span`
  margin-right: auto;
`;
