import styled from "styled-components";

import { OverlayContainer } from "containers/Modal/styles";

export const PurchaseOverlay = styled(OverlayContainer)`
  justify-content: flex-start;
`;

export const LoaderContainer = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
`;

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: width 3s ease-in-out;
`;

export const Background = styled(BaseBox)`
  background: ${({ theme }) => theme.customTheme.palette.black.standard};
  width: 100%;
`;

export const Progress = styled(BaseBox)<{
  percent?: number;
}>`
  background: ${({ theme }) => theme.customTheme.palette.red.standard};
  width: ${({ percent }) => percent}%;
`;

export const PurchaseModalContainer = styled.section`
  width: 100%;
  height: 100%;
  margin: auto;
  ${({ theme }) => theme.customTheme.containers.LogoutModalContainer};
`;

export const PurchaseLoaderBody = styled.div`
  height: calc(100% - 4px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.customTheme.palette.white.standard};
`;

export const ConfimationMessage = styled.span`
  font-family: ThemeFontLight;
  margin-right: 20px;
`;
