import styled from "styled-components";

export const HomePageBannerContainer = styled.div<{
  background?: string;
}>`
  background-image: ${({ background }) => (background ? `url(${background})` : "")};
  height: ${({ theme }) => theme.customTheme.pxToRem(240)};
  width: 100%;
  text-align: center;
  padding-top: calc(7.5rem - 37px);
  background-position: right center;
  ${({ theme }) => theme.customTheme.containers.HomePage.HomePageBannerContainer};

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    height: ${({ theme }) => theme.customTheme.pxToRem(110)};
    padding-top: calc(7.5rem - 90px);
  }
`;

export const PromotionsTitle = styled.span`
  border-radius: 12px;
  display: inline-flex;
  font-family: ThemeFontLight;
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(24)};
  line-height: ${({ theme }) => theme.customTheme.pxToRem(24)};
  max-width: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(27)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(27)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(30)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(30)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("mdl")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(38)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(38)};
  }
  ${({ theme }) => theme.customTheme.containers.HomePage.PromotionsTitle};
`;

export const PromotionsSubTitle = styled(PromotionsTitle)`
  font-family: ThemeFontLight;

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(20)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(24)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(16)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(20)};
  }

  ${({ theme }) => theme.customTheme.breakpoints.up("mdl")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(22)};
    line-height: ${({ theme }) => theme.customTheme.pxToRem(26)};
  }
`;
