import styled from "styled-components";

export interface IImgProps {
  width: string;
}

export const StyledRoundedImage = styled.img<IImgProps>`
  width: ${({ theme, width }) => (width ? width : theme.customTheme.pxToRem(28))};
  height: ${({ theme, width }) => (width ? width : theme.customTheme.pxToRem(28))};
  border-radius: 25px;
  margin-right: ${({ theme }) => theme.customTheme.spacing(1)};
`;
