import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setSource } from "state/config-slice";

interface SearchParams {
  get: (param: string) => string | null;
}

export const useSourceParams = (searchParams: SearchParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const source = searchParams.get("source");
    const s = searchParams.get("s");

    if (source) {
      dispatch(setSource(source));
    } else if (s) {
      dispatch(setSource(s));
    }
  }, [dispatch, searchParams]);
};
