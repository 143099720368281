import styled from "styled-components";

const getRotationFromDirection = (direction: string): number => {
  if (!direction) return 0;

  switch (direction.toLowerCase()) {
    case "left":
      return 135;
    case "right":
      return -45;
    case "up":
      return -135;
    case "down":
      return 45;
    default:
      return 0;
  }
};

export const StyledArrow = styled.span<{
  color: string;
  size: string;
  direction: string;
}>`
  border: 1px solid ${({ color }) => color};
  border-width: 0 1px 1px 0;
  display: inline-block;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  transform: rotate(${({ direction }) => getRotationFromDirection(direction)}deg) translate(0, -50%);
`;

export const ArrowWrapper = styled.div<{
  size: string;
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;
