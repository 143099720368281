import { FunctionComponent, ReactNode } from "react";

import { IconWrapper } from "./styles";

const LogoutModal: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  return <IconWrapper>{children}</IconWrapper>;
};

export default LogoutModal;
