export const getPageByStatusOffer = (statusOffer: string) => {
  switch (statusOffer.toLowerCase()) {
    case "available": {
      return "purchase";
    }

    case "cancelled": {
      return "cancellation";
    }

    case "purchased": {
      return "cancellation";
    }

    case "reserved": {
      return "acknowledge";
    }

    default: {
      return "";
    }
  }
};
