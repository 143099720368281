import { ReactElement } from "react";

import { PrimaryButton, SecondaryButton, LinkAsPrimaryButton, LinkAsSecondaryButton } from "./variants";
import Button from "./Button";

const RenderButtonComponent = (props: {
  type: string;
  onClick: () => void;
  title: string;
  key: string;
  id: string;
}): ReactElement => {
  const { type, ...restOfProps } = props;
  if (type === "secondary") {
    return <SecondaryButton {...restOfProps} />;
  }

  return <PrimaryButton {...restOfProps} />;
};

export { Button, PrimaryButton, SecondaryButton, RenderButtonComponent, LinkAsPrimaryButton, LinkAsSecondaryButton };
