import { FunctionComponent } from "react";
import VFUKLoader from "@vfuk/core-loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { StyledContainer } from "./styles";

import { configState } from "state/config-slice";

const Loader: FunctionComponent = () => {
  const { loader } = useSelector(configState);
  const { t } = useTranslation();

  if (loader.showLoader) {
    return (
      <StyledContainer>
        <VFUKLoader text={t(loader.message as string)} />
      </StyledContainer>
    );
  }

  return null;
};

export default Loader;
