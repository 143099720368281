import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

import { StyledBoxArea, StyledPageTitle, StyledContainer, StyledDescription, BackToHomepageButton } from "./styles";

import { PageContainer } from "containers/PageContainer";
import { sendULFEvent } from "state/config-slice";
import { ULFEvents } from "types/ULF";

const ErrorPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cookies] = useCookies();

  return (
    <PageContainer>
      <StyledContainer>
        <img src="static-ppe2/images/tobi-sad.png" alt="error"></img>
        <StyledPageTitle>
          <Trans i18nKey="An error was encountered" />
        </StyledPageTitle>
        <StyledDescription>
          <Trans i18nKey="Please Try Again" />
        </StyledDescription>

        <StyledBoxArea>
          <BackToHomepageButton
            id="error-page-back"
            onClick={() => {
              navigate("/");
              if (cookies.PPE_TRACKING == true) {
                dispatch(
                  sendULFEvent({
                    "event-name": ULFEvents.REDIRECT_TO_HOME_PAGE,
                  }),
                );
              }
            }}
            title={t("Home")}
          />
        </StyledBoxArea>
      </StyledContainer>
    </PageContainer>
  );
};

export default ErrorPage;
