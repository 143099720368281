// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const containers = (pxToRem: any, _breakpoints: any, palette: any, _typography: any) => ({
  Card: {
    CardContainer: {},
    CardContent: {},
    CardHeader: {},
    CardHeaderLeft: {},
    Heading: {},
    Subheading: {},
    PriceText: {},
  },
  OfferDetails: {
    PageContainer: {
      paddingTop: pxToRem(20),
      paddingBottom: pxToRem(20),
      ["> div"]: { boxShadow: palette.borderBoxPageContainer.border },
    },
    OfferBanner: {},
    OfferDetailsSection: {},
    OfferDetailsSectionMainSection: {},
    OfferDetailsHeader: {},
    OfferDetailsMainText: {},
    OfferDetailsHeading: {},
    OfferDetailsSubHeading: {},
    OfferDetailsOfferInfo: {},
    OfferDetailsAditionalText: {},
  },
  Breadcrumbs: {
    BreadcrumbsLink: {},
    BreadcrumbsWrapper: {},
    BreadcrumbsText: {},
  },
  HomePage: {
    HomePageBannerContainer: {},
    PromotionsTitle: {},
    CardGrid: {},
    BannerSection: {},
    Tabs: {},
    Tab: {},
  },
  PrimaryButton: {},
  SecondaryButton: {},
  LogoutModalContainer: {},
});

export default containers;
