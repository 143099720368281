import styled from "styled-components";

import { PrimaryButton } from "components/Button";

export const LogoutModalContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 32px;
  margin: auto;
  ${({ theme }) => theme.customTheme.containers.LogoutModalContainer};
`;

export const StyledPageTitle = styled.h1`
  font-family: ThemeFontRegular;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  opacity: 1;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-size: 24px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  line-height: 30px;
`;

export const StyledBoxArea = styled.section`
  display: contents;
  flex-direction: row;
  border-bottom: 1px solid #ebebeb;
  padding: 12px;
  gap: 20px;

  &:only-child,
  &:last-child {
    border: none;
  }
`;

export const LogoutButton = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  height: ${({ theme }) => theme.customTheme.pxToRem(40)};
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    margin: auto;
    width: fit-content;
  }
`;

export const BackToHomepageButton = styled.div`
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  border-bottom: 1px solid ${({ theme }) => theme.customTheme.palette.black.standard};
  cursor: pointer;
  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    margin: auto;
    width: fit-content;
  }
`;

export const IconStyled = styled.i`
  display: flex;
`;

export const HeaderLogOut = styled.div`
  margin-left: auto;
  height: 100px;
`;
