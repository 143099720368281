/*
  NOTE: IF CHANGING ANY STANDARD COLOR, PLEASE UPDATE THE VALUES ON utils/getPalleteColor
*/

const palette = {
  black: {
    standard: "#333333",
    dark: "#000000",
  },
  white: {
    standard: "#FFFFFF",
    dark: "#DADADA",
    vanished: "#FAFAFA",
  },
  grey: {
    standard: "rgb(244, 244, 244)",
    dark: "#666666",
    darkerStandard: "#999999",
    mid: "rgb(204, 204, 204)",
    background: "#EBEBEB",
    info: "#4a4d4e",
    text: "#333333",
  },
  orange: {
    standard: "#EB9700",
  },
  blue: {
    standard: "#53A9B8",
    dark: "#0000EE",
    info: "#005EA5",
    lightblue: "#00b0ca",
  },
  red: {
    standard: "#E60000",
    light: "rgba(230, 0, 0, 0.2)",
    error: "#BD0000",
  },
  semiTransparentBackground: {
    standard: "rgba(0, 0, 0, 0.5)",
  },
  green: {
    standard: "#009900",
  },
  borderBoxPageContainer: {
    border: "rgb(0 0 0 / 20%) 0px 2px 4px",
  },
  homePageTabs: {
    hoverBorder: "rgba(230, 0, 0, 0.2)",
    chosenBorder: "#E60000",
    unchosenText: "#999999",
    chosenText: "#333333",
  },
  yellow: {
    tooltipArrow: "#FECB00",
  },
};

export default palette;
