// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const containers = (pxToRem: any, _breakpoints: any, palette: any, _typography: any) => ({
  Card: {
    CardContainer: {},
    CardContent: {},
    CardHeader: {},
    CardHeaderLeft: {},
    Heading: {},
    Subheading: {},
    PriceText: {},
  },
  OfferDetails: {
    PageContainer: {
      paddingTop: pxToRem(20),
      paddingBottom: pxToRem(20),
      ["> div"]: { margin: "0px", maxWidth: "initial", boxShadow: palette.borderBoxPageContainer.border },
    },
    OfferBanner: { margin: "0px", maxWidth: "initial" },
    OfferDetailsSection: {
      margin: "0px auto",
      ["&:nth-child(2n)"]: {
        width: "100%",
        maxWidth: "initial",
      },
    },
    OfferDetailsSectionMainSection: { width: "1200px", margin: "0px auto" },
    OfferDetailsHeader: { width: "1200px" },
    OfferDetailsMainText: {},
    OfferDetailsHeading: {},
    OfferDetailsSubHeading: {},
    OfferDetailsOfferInfo: {},
    OfferDetailsAditionalText: {
      maxWidth: "1200px",
      margin: "0px auto",
    },
  },
  Breadcrumbs: {
    BreadcrumbsLink: {},
    BreadcrumbsWrapper: {},
    BreadcrumbsText: {},
  },
  HomePage: {
    HomePageBannerContainer: { backgroundColor: palette.orange.standard },
    PromotionsTitle: {},
    CardGrid: {},
    BannerSection: {},
    Tabs: {},
    Tab: {},
  },
  PrimaryButton: { backgroundColor: palette.orange.standard },
  SecondaryButton: {},
  LogoutModalContainer: {},
});

export default containers;
