import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledHeader = styled.header`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 56px;
  border-bottom: 1px solid rgb(204, 204, 204);
  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    height: 63px;
  }
`;

export const IconAnchor = styled(Link)`
  line-height: 16px;
  margin: auto 0px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  right: 0;
  z-index: 100;
  height: 56px;
  margin: 0px auto;
  border-bottom: 1px solid rgb(204, 204, 204);

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    height: 63px;
  }
  max-width: 1200px;
  background: ${({ theme }) => theme.customTheme.palette.white.standard};
`;

export const AccountContainer = styled.div`
  height: 32px;
  margin: auto 0px;
`;
// export const StyledDivider = styled.span`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border-bottom: 0.5px solid #ebebeb;
// `;

// export const StyledHorizontalDivider = styled.hr`
//   margin: 0 16px;
//   border: 0.5px solid #ebebeb;

//   &:last-child {
//     display: none;
//   }
// `;

export const StyledAccountNumber = styled.span`
  font-family: ThemeFontLight;
  line-height: 24px;
  color: #333333;
  font-size: 1.2em;
  margin-right: 6px;
`;

export const LogoutButton = styled.button`
  background-color: transparent;
  border: 0px;
  cursor: pointer;
  padding-right: ${({ theme }) => theme.customTheme.pxToRem(10)};
`;
