import { FunctionComponent } from "react";

import { StyledPartnerLogo } from "./styles";

interface IProps {
  partner: string;
  url?: string;
}

const PartnerLogo: FunctionComponent<IProps> = ({ partner, url }) => {
  return <StyledPartnerLogo src={url} alt={`${partner} logo`} width={"50%"} height={40} />;
};

export default PartnerLogo;
