import VFUKFooter from "@vfuk/core-footer";

import footerConfig from "./opco-defaults/index";

const Footer = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const opcoFooterConfig = footerConfig;

  return <VFUKFooter {...opcoFooterConfig} />;
};

export default Footer;
