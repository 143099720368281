import { FunctionComponent, MouseEventHandler, ReactElement } from "react";
import styled from "styled-components";

import Icon from "types/Icon";

export interface IButtonProps {
  id?: string;
  title?: string;
  icon?: ReactElement<Icon>;
  iconPosition?: "left" | "right";
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
}

const CustomButton = styled.button<{
  disabled: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.customTheme.palette.grey.dark9};
  border-radius: 6px;

  ${({ theme }) => theme.customTheme.typography.buttonText};
  width: fit-content;
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(25)};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme, disabled }) => (disabled ? theme.customTheme.palette.grey.standard : "transparent")};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  text-decoration: none;

  filter: brightness(100%);
  transition: filter 200ms ease;

  &:hover {
    filter: ${({ disabled }) => (disabled ? "brightness(100%)" : "brightness(150%)")};
  }
`;

const Button: FunctionComponent<IButtonProps> = ({
  id,
  title = "",
  icon,
  onClick,
  iconPosition = "left",
  className,
  disabled = false,
}) => {
  return (
    <CustomButton data-testid={id} onClick={onClick} className={className} disabled={disabled}>
      {icon && iconPosition === "left" && icon}
      {title}
      {icon && iconPosition === "right" && icon}
    </CustomButton>
  );
};

export default Button;
