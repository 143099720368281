import { Link } from "react-router-dom";
import styled from "styled-components";

import ButtonComponent from "./Button";

export const PrimaryButton = styled(ButtonComponent)`
  border: none;
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.customTheme.palette.grey.standard : theme.customTheme.palette.red.standard};
  ${({ theme }) => theme.customTheme.containers.PrimaryButton};
`;

export const LinkAsPrimaryButton = styled(Link)`
  ${({ theme }) => theme.customTheme.typography.buttonText};
  width: fit-content;
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(25)};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  cursor: pointer

  text-decoration: none;

  filter: brightness(100%);
  transition: filter 200ms ease;

  &:hover {
    filter: brightness(150%);
  }
  border: none;
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  background-color: ${({ theme }) => theme.customTheme.palette.red.standard};
  border: 1px solid ${({ theme }) => theme.customTheme.palette.grey.dark9};
  border-radius: 6px;
  text-decoration: none;

  ${({ theme }) => theme.customTheme.containers.PrimaryButton};
`;

export const SecondaryButton = styled(ButtonComponent)`
  border: none;
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.customTheme.palette.grey.standard : theme.customTheme.palette.grey.dark};
  ${({ theme }) => theme.customTheme.containers.SecondaryButton};
`;

export const LinkAsSecondaryButton = styled(Link)`
  ${({ theme }) => theme.customTheme.typography.buttonText};
  width: fit-content;
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(25)};
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;

  cursor: pointer;

  text-decoration: none;

  filter: brightness(100%);
  transition: filter 200ms ease;

  &:hover {
    filter: brightness(150%);
  }
  border: none;
  color: ${({ theme }) => theme.customTheme.palette.white.standard};
  background-color: ${({ theme }) => theme.customTheme.palette.grey.dark};
  border: 1px solid ${({ theme }) => theme.customTheme.palette.grey.dark9};
  border-radius: 6px;
  ${({ theme }) => theme.customTheme.containers.SecondaryButton};
`;
