import { FunctionComponent } from "react";

import { StyledTileImage } from "./styles";

interface IProps {
  partner: string;
  url?: string;
}

const TileImage: FunctionComponent<IProps> = ({ partner, url }) => {
  return (
    <>
      <StyledTileImage src={url} alt={`${partner} image`} width={"100%"} height={98} />
    </>
  );
};

export default TileImage;
