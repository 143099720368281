export enum ULFEvents {
  PAGE_VIEW = "PAGE_VIEW", // open any page (home, offer details, basket, checkout, dashboard, ...)
  REDIRECT_TO_HOME_PAGE = "REDIRECT_TO_HOME_PAGE",
  REACTIVATE_CLICKED = "REACTIVATE_CLICKED", // click reactivate button
  CANCEL_CLICKED = "CANCEL_CLICKED", // cancel button and X clicked
  REDIRECT_TO_PARTNER_PAGE = "REDIRECT_TO_PARTNER_PAGE", // after click on setup, click on "go to partnerName"
  REDIRECT_TO_EXTERNAL_LINK = "REDIRECT_TO_EXTERNAL_LINK", // after click on setup, click on "go to partnerName"
  SUBSCRIPTION_PURCHASE_CLICKED = "SUBSCRIPTION_PURCHASE_CLICKED",
  GET_OFFERS_REQUEST_SENT = "GET_OFFERS_REQUEST_SENT",
  GET_OFFERS_REQUEST_RECEIVED = "GET_OFFERS_REQUEST_RECEIVED",
  LOGOUT = "LOGOUT",
  GO_BACK = "GO_BACK",
}

export interface ULFData {
  channel: string;
  design: string;
  "event-name": ULFEvents;
  "offer-name": string | null;
  page: string;
  partner: string | null;
  source: string | "marketplace";
  "x-vf-trace-timestamp": number;
  reason: string | null;
  "device-type": string;
}
