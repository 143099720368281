// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typography = (pxToRem: any, breakpoints: any, palette: any) => ({
  h1: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    fontSize: "3.6rem",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
    [breakpoints.up("lg")]: {
      fontSize: "6.0rem",
      lineHeight: "130%",
    },
  },
  h2: {
    fontFamily: "ThemeFontLight",
    fontSize: "1.5em",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
  },
  h3: {
    fontFamily: "ThemeFontLight",
    fontSize: "1.17em",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
  },
  h4: {
    fontFamily: "ThemeFontBold",
    fontSize: "1.25rem",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
  },
  h6: {
    fontFamily: "ThemeFontBold",
    fontSize: "1.25rem",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
  },
  p: {
    fontFamily: "ThemeFontLight",
    fontSize: "1em",
    lineHeight: "135%",
    letterSpacing: "0",
    fontWeight: "400",
  },
  span1: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.black.standard,
    fontSize: pxToRem(22),
    lineHeight: 1.3,
    fontWeight: 400,
    marginTop: pxToRem(8),
    marginBottom: pxToRem(0),
  },
  span2: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.black.standard,
    fontSize: pxToRem(18),
    lineHeight: 1.3,
    fontWeight: 400,
    marginTop: pxToRem(8),
    marginBottom: pxToRem(0),
  },
  primaryText: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.black.standard,
    fontSize: pxToRem(20),
    lineHeight: 1.3,
    fontWeight: 400,
    marginTop: pxToRem(8),
    marginBottom: pxToRem(0),
  },
  b1: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    fontFamily: "ThemeFontBold",
    color: palette.black.standard,
    fontSize: pxToRem(22),
    lineHeight: 1.3,
    fontWeight: 800,
    marginTop: pxToRem(8),
    marginBottom: pxToRem(0),
  },
  buttonText: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.grey.dark,
    fontSize: pxToRem(20),
    lineHeight: 1.3,
    fontWeight: 400,
  },
  linkText: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.blue.dark,
    fontSize: pxToRem(18),
    lineHeight: 1.3,
    fontWeight: 400,
  },
  checkBoxText: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.black.standard,
    fontSize: pxToRem(18),
    lineHeight: 1.3,
    fontWeight: 400,
  },
  breadcrumbs: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.grey.dark,
    fontSize: pxToRem(16),
    lineHeight: 1.3,
    fontWeight: 400,
  },
  helpLinks: {
    // FontFamily: `${fonts.ppNeueMachinaLight}`,
    color: palette.grey.dark,
    fontSize: pxToRem(16),
    lineHeight: 1.3,
    fontWeight: 400,
    textDecoration: "none",
  },
  bottomSectionTitle: {
    fontFamily: "ThemeFontRegular",
    color: palette.black.standard,
    fontSize: pxToRem(20),
    lineHeight: 1.3,
    fontWeight: "normal",
  },
  bottomSectionSubTitle: {
    fontFamily: "ThemeFontRegular",
    color: palette.black.standard,
    fontSize: pxToRem(20),
    lineHeight: 1.3,
    fontWeight: "normal",
  },
  noOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
});

export default typography;
