import { IconTickOutline } from "@vfuk/source-hifi-light-icons";

import { Trans } from "react-i18next";

import { IconClose } from "@vfuk/source-system-icons";

import { useDispatch } from "react-redux";

import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import {
  Background,
  Progress,
  LoaderContainer,
  PurchaseModalContainer,
  PurchaseLoaderBody,
  ConfimationMessage,
  PurchaseOverlay,
} from "./styles";

import { HeaderLogOut } from "containers/LogoutModal/styles";
import { AppDispatch } from "state/reducers-combiner";

interface PurchaseLoadeProps {
  percent: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPercent: ActionCreatorWithPayload<any, string>;
}

export default function PurchaseLoader({ percent, setPercent }: PurchaseLoadeProps) {
  const dispatch: AppDispatch = useDispatch();

  return (
    <PurchaseOverlay>
      <PurchaseModalContainer>
        <LoaderContainer>
          <Background />
          <Progress percent={percent} />
        </LoaderContainer>
        <PurchaseLoaderBody>
          <HeaderLogOut>
            <span
              onClick={() => {
                dispatch(setPercent(0));
              }}
              onKeyDown={() => {
                dispatch(setPercent(0));
              }}
              data-testid="close-cancel-btn"
              role="button"
              tabIndex={0}
            >
              <IconClose inverse={false} size={5} />
            </span>
          </HeaderLogOut>
          <IconTickOutline size={5} />
          <ConfimationMessage>
            <Trans i18nKey="Purchasing your offer" />
          </ConfimationMessage>
        </PurchaseLoaderBody>
      </PurchaseModalContainer>
    </PurchaseOverlay>
  );
}
