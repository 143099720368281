import styled from "styled-components";

import { Legend } from "./InputStyles";
import { IInputGroup, IOption } from "./InputInterface";
import RadioButton from "./RadioButton";

const Fieldset = styled.fieldset`
  border: none;
`;

const Wrapper = styled.div`
  padding: 16px 10px;
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  flex-direction: column;
`;

const RadioButtonGroup = ({ label, options, onChange }: IInputGroup) => {
  function renderOptions() {
    return options.map(({ label, name, disabled }: IOption) => {
      const shortenedOptionLabel = label.replace(/\s+/g, "");
      const optionId = `radio-option-${shortenedOptionLabel}`;

      return (
        <RadioButton
          value={label}
          label={label}
          key={optionId}
          id={optionId}
          name={name}
          disabled={disabled}
          onChange={onChange}
        />
      );
    });
  }

  return (
    <Fieldset>
      <Legend>{label}</Legend>
      <Wrapper>{renderOptions()}</Wrapper>
    </Fieldset>
  );
};

export default RadioButtonGroup;
