import styled from "styled-components";

export const Label = styled.label<{ disabled?: boolean }>`
  font-family: ThemeFontLight;
`;

export const Legend = styled.legend`
  font-weight: bold;
  font-size: 1.1em;
  color: rgb(51, 51, 51);
  padding: 16px 0px;
  font-family: ThemeFontLight;
  text-align: center;
`;

export const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 22px;
  height: 22px;
  border: 1px solid ${({ theme }) => theme.customTheme.palette.blue.lightblue};
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 3.5px;
  }
  :checked {
    ::after {
      background-color: ${({ theme }) => theme.customTheme.palette.blue.lightblue};
    }
  }
`;
