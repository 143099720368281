import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IconTickOutline } from "@vfuk/source-mid-render-dark-icons";

import { useTheme } from "styled-components";

import { useTranslation } from "react-i18next";

import {
  OfferBanner,
  OfferDetailsSection,
  OfferDetailsHeader,
  OfferDetailsHeading,
  OfferDetailsSectionMainSection,
  OfferDetailsMainText,
} from "./styles";

import { PageContainer } from "containers/PageContainer";

import { selectOfferByPartner } from "state/offers-slice";
import Offer from "types/Offer";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import PartnerLogo from "components/PartnerLogo";
import { LinkAsPrimaryButton } from "components/Button";

const OfferComparison: FunctionComponent = () => {
  const { partner } = useParams<{ partner?: string }>();
  const theme = useTheme();
  const { t } = useTranslation();

  const selectedOffer: Offer = useSelector(selectOfferByPartner(partner));
  const comparisonDetails = selectedOffer.json?.ppe2?.comparison;
  if (!selectedOffer || !comparisonDetails) {
    return <Navigate to="/notfound" />;
  }

  const variablesToMap: [string, string[]][] = Object.entries(comparisonDetails.variables);

  return (
    <>
      <PageContainer customPageStyling={theme.customTheme.containers.OfferDetails.PageContainer}>
        <theme.customTheme.components.Breadcrumbs text={capitalizeFirstLetter(selectedOffer.partner)} />
        <OfferBanner
          src={selectedOffer.bannerImageUrl[2]}
          alt={`
            ${selectedOffer.partner} image
          `}
          id="offer-details-page-banner"
        />
        <OfferDetailsSection>
          <OfferDetailsSectionMainSection>
            <OfferDetailsHeader>
              <PartnerLogo partner={selectedOffer.partner} url={selectedOffer.logoUrl} />
              <OfferDetailsHeading>{capitalizeFirstLetter(selectedOffer.partner)}</OfferDetailsHeading>
            </OfferDetailsHeader>
            <OfferDetailsMainText dangerouslySetInnerHTML={{ __html: comparisonDetails.description }} />

            <table>
              <thead>
                <tr>
                  <th></th>
                  {comparisonDetails.offerNames.map((el: { name: string; presentationName: string }) => (
                    <th key={`offerName${el.presentationName}`}>{el.presentationName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {variablesToMap.map(([key, values]) => (
                  <tr key={`leftCollumn${key}`}>
                    <td>{key}</td>
                    {values.map((el: string | boolean, index: number) => (
                      <td key={`offerValue${key}${el}${index}`}>{el === true ? <IconTickOutline size={1} /> : el}</td>
                    ))}
                  </tr>
                ))}

                <tr>
                  <td>{t("Get offer")}</td>
                  {comparisonDetails.offerNames.map((el: { name: string; presentationName: string }) => (
                    <td key={`offerNameGetButton${el.presentationName}`}>
                      <LinkAsPrimaryButton
                        to={`/offer/${el.name}`}
                        key={`offerNameGetButton${el.presentationName}Button`}
                        id={`offerNameGetButton${el.presentationName}Button`}
                      >
                        {el.presentationName}
                      </LinkAsPrimaryButton>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </OfferDetailsSectionMainSection>
        </OfferDetailsSection>
      </PageContainer>
    </>
  );
};

export default OfferComparison;
