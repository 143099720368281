const redirectToPrevious = (opco: string) => {
  const search = window?.location?.search;
  const origin = window?.location?.origin;

  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const path = params.state ? atob(params.state) : "";

  if (path.length > 0 && path !== `/${opco}`) {
    window.location.href = `${origin}${path}`;
  }
};

export default redirectToPrevious;
