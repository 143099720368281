/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ConfigSlice, { ConfigSliceFromRoot } from "types/ConfigSlice";

import { API_URL } from "config";
import MonetaryFormat from "types/MonetaryFormat";
import { getDeviceSize, getDeviceType } from "utils/deviceDetect";
import getOpco from "utils/getOpco";

export const initialState: ConfigSlice = {
  loaded: true,
  loading: false,
  openidUrls: [],
  source: "",
  modal: {
    showModal: false,
    errorModal: false,
    cookieModal: false,
    title: "",
    description: "",
    children: null,
    textAlign: null,
    icon: null,
    type: "",
    size: "small",
    customButtons: null,
  },
  loader: {
    showLoader: true,
    message: "",
  },
  toast: {
    show: false,
    icon: null,
    children: null,
    type: "",
    id: "",
    ids: [],
  },
};

export const fetchConfigFromAPI = createAsyncThunk("config/fetchConfig", async () => {
  const response = await fetch(`${API_URL}/api/v3/config?opco=${getOpco()}`).then((response) => response.json());

  return { ...response };
});

export const sendULFEvent = createAsyncThunk("config/sendULFEvent", async (data: any, { getState }: any) => {
  const userInfo: { access_token: string; choosenChargingId: string } = getState().user.userInfo;
  const choosenChargingId = getState().user.choosenChargingId;

  const body = {
    channel: getDeviceSize(),
    design: "visual",
    "offer-name": null,
    partner: null,
    page: window.location.pathname,
    source: "",
    "country-code": getOpco(),
    "charging-type": "misisdn",
    "charging-id": choosenChargingId,
    "transation-id": "",
    "x-vf-trace-timestamp": Date.now(),
    reason: null,
    "device-type": getDeviceType(),
  };

  await fetch(`${API_URL}/api/v3/ulf`, {
    method: "POST",
    credentials: "include",
    headers: {
      Authorization: `Bearer ${userInfo.access_token}`,
      Accept: "application/json; charset=utf-8",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ ...body, ...data }),
  });
});

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        openidUrls: { ...action.payload.openidUrls },
      };
    },
    setModal: (state, action) => {
      return {
        ...state,
        modal: {
          ...initialState.modal,
          ...action.payload,
        },
      };
    },
    clearConfigState: () => {
      return {
        ...initialState,
      };
    },
    setLoader: (state, action) => {
      return {
        ...state,
        loader: {
          ...state.loader,
          ...action.payload,
        },
      };
    },
    setToast: (state, action) => {
      return {
        ...state,
        toast: {
          ...state.toast,
          ...action.payload,
        },
      };
    },
    setSource: (state, action) => {
      return {
        ...state,
        source: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfigFromAPI.fulfilled, (state, action) => {
        state.openidUrls = [...action.payload.openidUrls];
        state.monetaryFormat = { ...action.payload.monetaryFormat };

        if (!action.payload.redirect_url) {
          state.loaded = true;
          state.loading = false;
        }
      })
      .addCase(fetchConfigFromAPI.pending, (state) => {
        state.loaded = false;
        state.loading = true;
      });
  },
});

export const { setConfig, setModal, clearConfigState, setLoader, setToast, setSource } = configSlice.actions;

export const configState = (state: ConfigSliceFromRoot): ConfigSlice => state.config;

export const monetaryConfig = (state: ConfigSliceFromRoot): MonetaryFormat | undefined => state.config.monetaryFormat;

export default configSlice.reducer;
