import { FunctionComponent, ReactNode } from "react";

import { PageContainerBackgroundOuter, PageContainerBackgroundInsider } from "./styles";

export const PageContainer: FunctionComponent<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customPageStyling?: any;
  children?: ReactNode;
}> = ({ customPageStyling, children }) => {
  return (
    <PageContainerBackgroundOuter customPageStyling={customPageStyling}>
      <PageContainerBackgroundInsider>{children}</PageContainerBackgroundInsider>
    </PageContainerBackgroundOuter>
  );
};
