import { FunctionComponent } from "react";

import { useTheme } from "styled-components";

import { ArrowWrapper, StyledArrow } from "./styles";

interface IProps {
  color?: string;
  size?: number;
  direction: string;
}

const Arrow: FunctionComponent<IProps> = ({ color, size = 20, direction }) => {
  const theme = useTheme();

  const chosenColor = color || theme.customTheme.palette.red.standard;

  return (
    <ArrowWrapper size={`${size}px`} data-testid="arrow">
      <StyledArrow direction={direction} color={chosenColor} size={`${Math.hypot(size / 2, size / 2)}px`} />
    </ArrowWrapper>
  );
};

export default Arrow;
