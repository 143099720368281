import { createGlobalStyle } from "styled-components";

import getImageUrl from "utils/getImageUrl";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-display: block;
  font-family: "ThemeFontLight";
  src: url(${getImageUrl("/fonts/VodafoneLt-full.eot?#iefix")});
  src: url(${getImageUrl("/fonts/VodafoneLt-full.eot?#iefix")}) format("embedded-opentype"), 
       url(${getImageUrl("/fonts/VodafoneLt-full.woff")}) format("woff"), 
       url(${getImageUrl("/fonts/VodafoneLt-full.ttf")}) format("truetype"), 
       url(${getImageUrl("/fonts/VodafoneLt-full.svg#VodafoneLt")}) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: block;
  font-family: "ThemeFontRegular";
  src: url(${getImageUrl("/fonts/VodafoneRg.eot?#iefix")});
  src: url(${getImageUrl("/fonts/VodafoneRg.eot?#iefix")}) format("embedded-opentype"), 
       url(${getImageUrl("/fonts/VodafoneRg.woff")}) format("woff"), 
       url(${getImageUrl("/fonts/VodafoneRg.ttf")}) format("truetype"), 
       url(${getImageUrl("/fonts/VodafoneRg.svg#VodafoneLt")}) format("svg");
  font-weight: normal;
  font-style: normal;
}

/*@font-face {
  font-display: block;
  font-family: "ThemeFontMedium";
  src: url("${getImageUrl("/fonts/diodrum-bold.eot")});
  src: url("${getImageUrl("/fonts/diodrum-bold.eot")}) format("embedded-opentype"), 
       url("${getImageUrl("/fonts/diodrum-bold.woff")}) format("woff"),
       url("${getImageUrl("/fonts/diodrum-bold.woff2")}) format("woff2"),
       url("${getImageUrl("/fonts/diodrum-bold.svg")}) format("svg");
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
  font-display: block;
  font-family: "ThemeFontBold";
  src: url(${getImageUrl("/fonts/VodafoneRgBold-full.eot?#iefix")});
  src: url(${getImageUrl("/fonts/VodafoneRgBold-full.eot?#iefix")}) format("embedded-opentype"), 
       url(${getImageUrl("/fonts/VodafoneRgBold-full.woff")}) format("woff"), 
       url(${getImageUrl("/fonts/VodafoneRgBold-full.ttf")}) format("truetype"), 
       url(${getImageUrl("/fonts/VodafoneRgBold-full.svg#VodafoneRgBold")}) format("svg");
  font-weight: normal;
  font-style: normal;
}
  body {
    margin: 0;
    padding: 0;
    font-family: ThemeFontRegular, Open-Sans, Helvetica, Sans-Serif;
    background: #fff;
  }


  *{
    box-sizing:border-box;
  }
`;

export default GlobalStyle;
