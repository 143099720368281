import { FunctionComponent } from "react";

import { CountryLabel, CountryLink, CountryRoundedImage } from "./style";

import Arrow from "components/Arrow";

interface ICountrys {
  route: string;
  name: string;
  src: string;
}

interface ICountryMapperProps {
  countrys: ICountrys[];
}

const CountryMapper: FunctionComponent<ICountryMapperProps> = ({ countrys }) => {
  return (
    <>
      {countrys.map((c) => (
        <CountryLink to={c.route} key={c.route} data-testid={c.route}>
          <CountryRoundedImage width={"37px"} alt={c.name} src={c.src}></CountryRoundedImage>
          <CountryLabel>{c.name}</CountryLabel>
          <Arrow direction={"right"} size={16} />
        </CountryLink>
      ))}
    </>
  );
};

export default CountryMapper;
