import styled from "styled-components";

export const OfferBanner = styled.img`
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  display: flex;
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferBanner};
`;

export const OfferDetailsSection = styled.section`
  padding: ${({ theme }) => theme.customTheme.pxToRem(30)} ${({ theme }) => theme.customTheme.pxToRem(20)};

  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.customTheme.palette.grey.background};
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsSection};
`;

export const OfferDetailsSectionMainSection = styled.div`
  svg {
    fill: ${({ theme }) => theme.customTheme.palette.green.standard};
  }
  td,
  th {
    text-align: center;
    vertical-align: middle;
    * {
      margin: auto;
    }
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsSectionMainSection};
`;

export const OfferDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.customTheme.pxToRem(20)};
  margin-bottom: ${({ theme }) => theme.customTheme.pxToRem(20)};
  & > img {
    width: 100px;
    margin-top: 0px;
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsHeader};
`;

export const OfferDetailsHeading = styled.h3`
  ${({ theme }) => theme.customTheme.typography.h3};
  margin-bottom: 0px;
  margin-top: 8px;
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsHeading};
`;

export const OfferDetailsMainText = styled.p`
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
  }
  padding-top: ${({ theme }) => theme.customTheme.pxToRem(10)};

  * {
    ${({ theme }) => theme.customTheme.typography.primaryText};
    margin-top: ${({ theme }) => theme.customTheme.pxToRem(10)};
  }
  ${({ theme }) => theme.customTheme.containers.OfferDetails.OfferDetailsMainText};
`;
