import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";

import { IconInfoCircle } from "@vfuk/source-mid-render-light-icons";

import {
  CookiesBtn,
  StyledCookieButtonsArea,
  StyledCookieDescription,
  StyledCookieHeader,
  StyledCookieContainer,
  StyledCookieTitle,
  OverlayContainer,
} from "./styles";

import { AppDispatch } from "state/reducers-combiner";
import { setModal } from "state/config-slice";

const CookiesModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const [, setCookie] = useCookies();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <OverlayContainer>
      <StyledCookieContainer type="cookies">
        <StyledCookieHeader>
          <IconInfoCircle size={1} />
          <StyledCookieTitle>{t("Cookies & Tracking")}</StyledCookieTitle>
        </StyledCookieHeader>

        <StyledCookieDescription
          dangerouslySetInnerHTML={{
            __html: t(
              "We use cookies and similar technologies to keep you logged in to this application and to monitor its performance. By accepting all cookies you are consenting to the use of performance cookies as detailed in our <a href='https://www.vodafone.es/c/conocenos/es/vodafone-espana/quienes-somos/legal-y-regulatorio/politica-de-privacidad-y-cookies/' target={'_blank'} rel='noreferrer'>Cookie Policy</a>",
            ),
          }}
        ></StyledCookieDescription>
        <StyledCookieButtonsArea>
          <CookiesBtn
            id="accept-cookies"
            onClick={() => {
              setCookie("PPE_COOKIE_CONSENT_GRANTED", true, { secure: true, sameSite: "strict" });
              setCookie("PPE_TRACKING", true, { secure: true, sameSite: "none" });
              dispatch(setModal({ showModal: false }));
            }}
            title={t("Accept & continue")}
          />
          <CookiesBtn
            id="manage-cookies"
            onClick={() => {
              setCookie("PPE_COOKIE_CONSENT_GRANTED", true, { secure: true, sameSite: "strict" });
              navigate("cookie-preferences");
              dispatch(setModal({ showModal: false }));
            }}
            title={t("Manage Preferences")}
          />
          <CookiesBtn
            id="reject-cookies"
            onClick={() => {
              setCookie("PPE_TRACKING", false, { secure: true, sameSite: "strict" });
              setCookie("PPE_COOKIE_CONSENT_GRANTED", true, { secure: true, sameSite: "strict" });

              dispatch(setModal({ showModal: false }));
            }}
            title={t("Reject optional cookies")}
          />
        </StyledCookieButtonsArea>
      </StyledCookieContainer>
    </OverlayContainer>
  );
};

export default CookiesModal;
