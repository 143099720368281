import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  DoneButton,
  ButtonContainer,
  StyledDescription,
  OverlayContainer,
  StyledContainer,
  StyledTitle,
} from "./styles";

import { AppDispatch } from "state/reducers-combiner";
import { setModal } from "state/config-slice";

const ErrorModal: FunctionComponent = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  return (
    <OverlayContainer>
      <StyledContainer type={"error"} size={"small"}>
        <StyledTitle>{t("Oops! Technical error")}</StyledTitle>
        <StyledDescription>{t("Please try again or reload the page")}</StyledDescription>

        <ButtonContainer>
          <DoneButton
            title={t("Done")}
            id="close-error-modal"
            onClick={() => {
              dispatch(setModal({ showModal: false }));
            }}
          ></DoneButton>
        </ButtonContainer>
      </StyledContainer>
    </OverlayContainer>
  );
};

export default ErrorModal;
