import styled from "styled-components";

import { PrimaryButton } from "components/Button";

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 24px;
`;

export const StyledPageTitle = styled.span`
  font-family: ThemeFontBold;
  font-size: ${({ theme }) => theme.customTheme.pxToRem(38)};
  line-height: ${({ theme }) => theme.customTheme.pxToRem(38)};
  text-align: center;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};

  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.customTheme.pxToRem(24)};
  }
`;

export const StyledDescription = styled.span`
  font-family: ThemeFontLight;
  text-align: center;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-size: 20px;
  line-height: 24px;
`;

export const StyledBoxArea = styled.section`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 12px;

  &:only-child,
  &:last-child {
    border: none;
  }
`;

export const BackToHomepageButton = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  height: ${({ theme }) => theme.customTheme.pxToRem(40)};
  width: 100%;
`;

export const IconStyled = styled.i`
  display: flex;
`;
