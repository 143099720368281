import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { TooltipBase, ToolTipText } from "./styles";

interface tProps {
  children: string;
  id?: string;
}
const CustomTolltip: FunctionComponent<tProps> = ({ children, id }) => {
  const { t } = useTranslation();

  return (
    <TooltipBase data-testid={id}>
      <ToolTipText>{t(children)}</ToolTipText>
    </TooltipBase>
  );
};

export default CustomTolltip;
