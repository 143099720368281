export const getDeviceSize = () => {
  const width = window.innerWidth;

  if (width < 600) return "mobile";

  if (width >= 600 && width < 1024) return "tablet";

  return "desktop";
};

export const getDeviceType = () => {
  const info = window.navigator.userAgent.split(")")[0];

  if (info.includes("iPhone") || info.includes("iPad")) {
    return "iOS";
  }

  if (info.includes("Android")) {
    return "Android";
  }

  if (info.includes("Macintosh")) {
    return "MacOS";
  }

  if (info.includes("Windows")) {
    return "Windows";
  }

  if (info.includes("Linux")) {
    return "Linux";
  }

  return "";
};
