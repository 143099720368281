import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import {
  DoneButton,
  ButtonContainer,
  StyledDescription,
  OverlayContainer,
  StyledBigIcon,
  StyledContainer,
  StyledTitle,
  ChildrenWrapper,
} from "./styles";

import ErrorModal from "./ErrorModal";

import CookiesModal from "./CookiesModal";

import { AppDispatch } from "state/reducers-combiner";
import { setModal, configState } from "state/config-slice";

const Modal: FunctionComponent = () => {
  const { t } = useTranslation();

  const currentConfig = useSelector(configState);
  const dispatch: AppDispatch = useDispatch();
  const modal = currentConfig && currentConfig.modal;

  const {
    icon,
    title,
    description,
    type,
    children,
    size,
    customButtons,
    showModal = undefined,
    errorModal,
    cookieModal,
    textAlign,
  } = modal;

  if (showModal) {
    if (errorModal) {
      return <ErrorModal />;
    }

    if (cookieModal) {
      return <CookiesModal />;
    }

    return (
      <OverlayContainer>
        <StyledContainer type={type} size={size}>
          {icon ? <StyledBigIcon>{icon}</StyledBigIcon> : null}

          {title && <StyledTitle textAlign={textAlign}>{title}</StyledTitle>}
          {description && <StyledDescription textAlign={textAlign}>{description}</StyledDescription>}

          {children && <ChildrenWrapper textAlign={textAlign}>{children}</ChildrenWrapper>}

          {customButtons ? (
            customButtons
          ) : (
            <ButtonContainer>
              <DoneButton
                id="done-option"
                title={t("Done")}
                onClick={() => {
                  dispatch(setModal({ showModal: false }));
                }}
              ></DoneButton>
            </ButtonContainer>
          )}
        </StyledContainer>
      </OverlayContainer>
    );
  }

  return null;
};

export default Modal;
