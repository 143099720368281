export default {
  social: [
    {
      id: "social-twitter",
      srText: "Twitter",
      icon: "twitter",
      href: "https://www.twitter.com/vodafoneNL",
    },
    {
      id: "social-facebook",
      srText: "Facebook",
      icon: "facebook",
      href: "https://www.facebook.com/VodafoneNL",
    },
    {
      id: "linkedin",
      srText: "Linkedin",
      icon: "linkedin",
      href: "https://www.linkedin.com/company/vodafone",
    },
    {
      id: "youtube",
      srText: "Youtube",
      icon: "youtube",
      href: "https://www.youtube.com/user/vodafonenl",
    },
  ],
  legalLinks: [
    // {
    //   id: "privacy-policy",
    //   text: "Privacy Policy",
    //   href: "https://www.vodafone.nl/support/over-deze-website/privacy-en-disclaimer.shtml",
    // },
    // {
    //   id: "cookie-policy",
    //   text: "Cookie Policy",
    //   href: "https://www.vodafone.nl/over-deze-website/cookies.shtml",
    // },
    // {
    //   id: "terms-conditions",
    //   text: "Cookie PolicyAlgemene voorwaarden",
    //   href: "https://www.vodafone.nl/support/abonnement-en-rekening/tarieven-en-voorwaarden.shtml#abonnementen",
    // },
  ],
  companyInfo: "",
  copyrightText: "",
};
