import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import spacing from "./spacing";
import containers from "./containers";

import Breadcrumbs from "./components/Breadcrumbs";
import Footer from "./components/Footer";
import Header from "./components/Header";

import ThemeInterface from "types/ThemeInterface";

const pxToRemDefinition = (pixels: number) => `${pixels / 16}rem`;

const ziggo: ThemeInterface = {
  customTheme: {
    palette,
    breakpoints,
    typography: typography(pxToRemDefinition, breakpoints, palette),
    containers: containers(
      pxToRemDefinition,
      breakpoints,
      palette,
      typography(pxToRemDefinition, breakpoints, palette),
    ),
    spacing,
    pxToRem: pxToRemDefinition,
    components: {
      Breadcrumbs: Breadcrumbs,
      Footer: Footer,
      Header: Header,
    },
  },
  formFields: {
    backgroundColor: "white",
    selected: {
      backgroundColor: "rgb(0, 126, 146)",
    },
  },
};

export default ziggo;
