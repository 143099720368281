import styled from "styled-components";

export const PageContainerBackgroundOuter = styled.section<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customPageStyling?: any;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.customTheme.palette.grey.standard};
  ${({ customPageStyling }) => customPageStyling};
`;

export const PageContainerBackgroundInsider = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0px auto;
  flex-direction: column;
  width: 100%;
`;
