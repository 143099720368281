import styled from "styled-components";

export const StyledPartnerLogo = styled.img`
  margin-top: 5px;
  max-width: 147px;
  ${({ theme }) => theme.customTheme.breakpoints.down("md")} {
    width: 94px;
    height: 25px;
  }
`;
