// eslint-disable-next-line import/named
import { PageFooter } from "@vodafone-partnerservices/react-ui";

import getOpco from "utils/getOpco";

const Footer = () => {
  const opco = getOpco().length > 0 ? getOpco() : "gb";

  return <PageFooter opco={opco} />;
};

export default Footer;
