import styled from "styled-components";

import { PrimaryButton } from "components/Button";

export const CancelConfimationMessage = styled.span`
  font-family: ThemeFontLight;
  text-align: center;
  font-size: 24px;
`;

export const CancelLoaderTitle = styled.h3`
  font-family: ThemeFontLight;
  font-size: 3em;
  text-align: center;
  opacity: 1;
  color: ${({ theme }) => theme.customTheme.palette.black.standard};
  font-style: normal;
  letter-spacing: 0px;
  line-height: 30px;
`;

export const CancelLoaderBody = styled.div<{
  concluded?: boolean;
}>`
  height: calc(100% - 4px);
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.customTheme.palette.white.standard};
  gap: ${({ concluded }) => (concluded ? "32px" : "64px")};
`;

export const BackToOffersButton = styled(PrimaryButton)`
  padding: ${({ theme }) => theme.customTheme.pxToRem(10)} ${({ theme }) => theme.customTheme.pxToRem(24)};
  font-size: ${({ theme }) => theme.customTheme.pxToRem(18)};
  height: ${({ theme }) => theme.customTheme.pxToRem(40)};
  width: 100%;

  ${({ theme }) => theme.customTheme.breakpoints.up("md")} {
    width: fit-content;
  }
`;
