interface CountryDonwload {
  [key: string]: {
    [key: string]: {
      url_google: string;
      img_google: string;
      url_apple: string;
      img_apple: string;
      desktopInfo: {
        label: string;
        url: string;
      };
    };
  };
}

export const downloadValues: CountryDonwload = {
  ro: {
    netflix: {
      url_google: "https://play.google.com/store/apps/details?id=com.netflix.mediaclient",
      img_google: "static-ppe2/images/downloads/ro-google-play.png",
      url_apple: "https://apps.apple.com/us/app/netflix/id363590051",
      img_apple: "static-ppe2/images/downloads/ro-app-store.png",
      desktopInfo: {
        label: "Go to netflix.com",
        url: "https://www.netflix.com",
      },
    },
  },
};
